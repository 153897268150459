/* @import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap'); */
body {
  font-family: "Lato", sans-serif;
  margin: 0;
/* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
  "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
  "Helvetica Neue", sans-serif; */
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
  background-color: rgba(199, 199, 199, 0.4);
}
.rec.rec-arrow {
  background-color: transparent;
  border-color: transparent;
  border-radius: 50%;
  box-shadow: none;
}
.rec.rec-arrow:hover, .rec.rec-arrow:hover:enabled, .bSWeAW:hover:enabled, .bSWeAW:focus:enabled {
  background-color: #939598;
  color: #fff;
}
.rec.rec-arrow:disabled {
  visibility: hidden;
}